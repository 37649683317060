header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========== CTA ========== */
.cta {
    padding-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== HEADER SOCIAL ========== */
.header__socials {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 3rem;
    left:0;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========== ME ========== */
.me {
     background: linear-gradient(var(--color-primary), transparent);
     width: 22rem;
     height: 37rem;
     position: absolute;
     left: calc(50% - 11rem);
     margin-top: 4rem;
     border-radius: 12rem 12rem 0 0;
     overflow: hidden;
     padding: 0 1rem 0rem 1rem;
}

/* ========== SCROLL DOWN ========== */
.scroll__down{
    position: absolute;
    font-weight: 300;
    transform: rotate(90deg);
    bottom: 5rem;
    right: -2.3rem;
    font-size: 0.9rem;
}

 /* ==========(MEDIA QUERIES SMALL SEVICES)========== */

@media screen and (max-width: 600px) { 
    .header__socials,.scroll__down {
        display: none;
    }
 }